import React from "react"
import "./ugSlider.scss"
import Slick from "react-slick"
import Pagination from "./pagination"
import Img from "./img"
import "./mvpSlider.scss"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const students = [{
  avatar: "https://img.uigreat.com/pxfer/dashi.jpg",
  name: "大师™",
  intro: "「大师聊设计」品牌创始人、UIGREAT Studio负责人、10w粉丝抖音博主、交互设计专家，被誉为「看了他的文章就想催更」的男人"
}, {
  avatar: "https://img.uigreat.com/pxfer/zhaowei.jpg",
  name: "赵威",
  intro: "站酷百万人气设计师、陌陌高级UI设计师、UIGREAT Studio队长、服务过的客户包括梅赛德斯中国、同道大叔、Barbara等多家国内外企业。"
}, {
  avatar: "https://img.uigreat.com/pxfer/avatar3.jpg",
  name: "y园糖",
  intro: "知名网红插画师、知乎视觉负责人、y园糖插画班创始人、像素范儿真正走向“财富自由”的自由插画师，全网搜索“y园糖”全是出自她学生的作品"
}, {
  avatar: "https://img.uigreat.com/pxfer/avatar4.jpg",
  name: "林纸巾",
  intro: "前马桶MT设计负责人、现在抖音70W+粉丝大V、Vlog拍摄红人、对于镜头语言的设计有独门技巧，抖音对于”中岛美嘉“解读，唯一兼顾镜头设计和语言的第一人"
}, {
  avatar: "https://img.uigreat.com/pxfer/avatar5.jpg",
  name: "活力占",
  intro: "抖音200W+大V、设计冷知识的唯一原创者、幽默风趣的从另一角度解读互联网科技冷知识，在抖音还真是前无古人后无来者"
}]

let settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  initialSlide: 2,
  centerMode: true,
  variableWidth: true,
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: false,
        centerMode: false,
      }
    }
  ]
};

class MVPSlider extends React.Component {
  constructor(props) {
    super(props)
    this.sliderRef = React.createRef()
  }

  state = {
    current: 3
  }

  render() {
    return (
      <div className={`mvp-slider`}  data-sal="slide-up" data-sal-delay="100" data-sal-duration="1000" data-sal-easing="ease">
        <div className="mvp-intro"><span style={{ fontWeight: "700" }}>{students[this.state.current - 1].name}：</span>{students[this.state.current - 1].intro}</div>
        <Slick className={`slider`}
          {...settings}
          arrows={false}
          ref={this.sliderRef}
          beforeChange={(old, current) => {
            this.setState({
              current: current + 1
            })
          }}
        >
          {
            students.map((item, index) =>
              <div className={`slider-item ${this.state.current}`} key={index}>
                <div className={`mvp-item ${this.state.current === index + 1 ? "mvp-item-current" : ""}`} >
                  <div className="mvp-cover"></div>
                  <div className="mvp-name">{item.name}</div>
                  <Img src={item.avatar} draggable={false} alt={""} />
                </div>
              </div>
            )
          }
        </Slick>
        <Pagination className={"mvp-pagination"} current={this.state.current || 1} total={students.length} onNext={() => {
          this.sliderRef.current.slickNext()
        }} onPrev={() => {
          this.sliderRef.current.slickPrev()
        }} />
      </div>

    )
  }
}

export default MVPSlider
