import React from "react"
import "./panel-title.scss"
import Img from "./img"
export default props => (
    <div className={`panel-title ${props.className || ""} ${props.align || ""}`} style={props.style || {}}  data-sal="slide-up" data-sal-delay="100" data-sal-duration="1000" data-sal-easing="ease">
        <Img draggable={false} src={props.img} alt="" />
        <div className={`en-title ${props.color || ""}`}>{props.enTitle}</div>
        <div className={`main-title ${props.color || ""}`}>{props.mainTitle}</div>
        <div className={`sub-title ${props.color || ""}`} style={{textAlign:`${props.align || ""}`}}>{props.subTitle}</div>
    </div>
)

