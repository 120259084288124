import React from "react"
import "./ugSlider.scss"
import Slick from "react-slick"
import Pagination from "./pagination"
import Img from "./img"
import { Row, Col } from 'antd'
import "./teacherSlider.scss"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const teachers = [{
  name: "泡泡老师",
  enName: "Pao Pao",
  subtitle: "资深设计讲师",
  desc: "曾担任iosbar中国（英国4A广告公司）资深美术指导，服务过的客户包含：华为、大众汽车、腾讯、海尔、飞利浦和康师傅等国内外知名品牌。曾为国内某知名CG教育培训机构UI线上课程负责人。其学员多次获得互联网设计比赛大奖（魅族、墨迹天气等），并且学员遍布百度、腾讯、优酷和网易等一线互联网公司）",
  showSrc: "https://img.uigreat.com/pxfer/teacher-show2.png",
  bg: ["https://img.uigreat.com/pxfer/20200309151607.png","https://img.uigreat.com/pxfer/20200309153803.png"]
}, {
  name: "Limi老师",
  enName: "Limi",
  subtitle: "资深设计讲师",
  desc: "服务过宝马、联想、三菱、vivo和山东省商会等知名品牌。曾任职金融之家视觉设计主管，负责金融之家移动端APP与web端在线商城等产品设计。曾就职于呱呱视频，负责其主产品界面设计。创办设计师互动交流学习社区UIGREAT。",
  showSrc: "https://img.uigreat.com/pxfer/teacher-show4.png",
  bg: ["https://img.uigreat.com/pxfer/20200309122137.png","https://img.uigreat.com/pxfer/20200309122225.png"]
}, {
  name: "果冻老师",
  enName: "Golden Joe",
  subtitle: "高级视觉讲师+课程研发",
  desc: " 多年视觉设计工作经验，一线互联网公司担任多年主要视觉设计负责人，给多个公司（宝马、TCL、华为等）做设计项目，带出的学生服务于BAT、美团、滴滴等一线互联网公司；有一套成熟的教学思路，结合思路和体系融入到教学和课程研发中，用独特的通俗易懂的方式教授学生，也会跟进学生毕业后的反馈和难题",
  showSrc: "https://img.uigreat.com/pxfer/teacher-show1.png",
  bg: ["https://img.uigreat.com/pxfer/20200309103922.png"]
}, {
  name: "琳琳老师",
  enName: "Lin Lin",
  subtitle: "高级视觉讲师+课程研发",
  desc: "研究生从事智能康复机器人领域HCI相关研究，深入学习国际人机交互最新理念；毕业后加入像素范儿投身于UI设计，对互联网产品具有透彻独到的见解；更新像素范儿所有教学课件，具有大型演讲Keynote制作经验；致力于帮助每位学员完成自己的设计梦想。",
  showSrc: "https://img.uigreat.com/pxfer/teacher-show3.png",
  bg: ["https://img.uigreat.com/pxfer/20200309144717.png","https://img.uigreat.com/pxfer/20200309144717.png","https://img.uigreat.com/pxfer/20200309144717.png"]
}]


class TeacherSlider extends React.Component {
  constructor(props) {
    super(props)
    this.sliderRef = React.createRef()
  }

  state = {
    current: 1,
    changeStatus: 0,
  }

  render() {
    return (
      <div className={`teacher-slider`}  data-sal="slide-up" data-sal-delay="100" data-sal-duration="1000" data-sal-easing="ease">
        <Row type="flex" align="bottom">
          <Col xs={24} md={10} className={`col-left`}>
            <div className={`teacher-intro`} data-sal="slide-up">
              <div className={`${this.state.changeStatus === 1 ? "change-before" : this.state.changeStatus === 2 ? "change-after" : ""}`}>
                <div className={`name`}>{teachers[this.state.current - 1].name}</div>
                <div className={`en-name`}>{teachers[this.state.current - 1].enName}</div>
                <div className={`subtitle`}>
                  {teachers[this.state.current - 1].subtitle}
                </div>
                <div className={`desc`}>
                  {teachers[this.state.current - 1].desc}
                </div>
              </div>
            </div>
            <Pagination className={`teacher-pagination `} current={this.state.current || 1} total={teachers.length || 0} onNext={() => {
              this.sliderRef.current.slickNext()
            }} onPrev={() => {
              this.sliderRef.current.slickPrev()
            }} />
          </Col>
          <Col xs={24} md={14} className={`col-right`}>
            <div className="slick-outter">
              <Slick className={`slider`}
                dots={false}
                infinite={true}
                speed={500}
                slidesToScroll={1}
                slidesToShow={1}
                // initialSlide={0}
                arrows={false}
                variableWidth={false}
                ref={this.sliderRef}
                centerMode={true}
                centerPadding={0}
                // adaptiveHeight={true}
                beforeChange={(oldCurrent, current) => {
                  this.setState({
                    changeStatus: 1
                  })
                }}
                afterChange={current => {
                  this.setState({
                    current: current + 1,
                    changeStatus: 2
                  })
                }}
              >
                {
                  teachers.map((item, index) =>
                    <div className={`slider-item slider-item-${index}`} key={index} >
                      <div className={`teacher-item`} >
                        <div className={`teacher-bg-panel`}>
                          <img className={`teacher-bg teacher-bg-${index}`} style={{transition:"1s all 0.5s ease"}} src={item.bg[0] || ""} alt="" />
                          <img className={`teacher-bg teacher-bg-${index}`} style={{transition:"1s all 0.7s ease"}} src={item.bg[1] || ""} alt="" />
                          <img className={`teacher-bg teacher-bg-${index}`} style={{transition:"1s all 0.9s ease"}} src={item.bg[2] || ""} alt="" />
                        </div>
                        <Img className={`teacher-show`} src={item.showSrc} draggable={false} alt={""} />
                      </div>
                    </div>
                  )
                }
              </Slick>
            </div>
          </Col>
        </Row>
      </div>

    )
  }
}

export default TeacherSlider
