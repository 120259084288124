import React from "react"
import Img from "./img"
import { BackTop, Drawer } from 'antd';
import { MenuFoldOutlined } from '@ant-design/icons'
import "./nav.scss"


// const { Link } = Anchor;
const nav = [
    {
        title: "大纲",
        targetId: "#outline"
    }, {
        title: "联系",
        targetId: "#contact"
    }, {
        title: "去向",
        targetId: "#go"
    }, {
        title: "成就",
        targetId: "#achieve"
    }, {
        title: "更新",
        targetId: "#update"
    }, {
        title: "圈子",
        targetId: "#circle"
    }, {
        title: "课程矩阵",
        targetId: "#matrix"
    }, {
        title: "计划",
        targetId: "#plan"
    }, {
        title: "大神",
        targetId: "#big"
    }, {
        title: "讲师",
        targetId: "#lector"
    },]

class Nav extends React.Component {
    constructor(props) {
        super(props)
        this.navRef = React.createRef();
        this.indicatorRef = React.createRef();
    }
    state = {
        drawerVisible: false,
        width: 126,
        left: -5,
        showNavBlock: false
    }

    navItemsTop = {

    }

    currentNav = null
    lastScrollTop = 0;
    componentDidMount() {

        window.onscroll = this.windowOnScroll


        for (let item of nav) {
            let itemDom = document.getElementById(item.targetId.replace(`#`, ""));
            this.navItemsTop[item.targetId] = itemDom.offsetTop;
        }

    }

    windowOnScroll = e => {
        let targetId = "";
        for (let id in this.navItemsTop) {
            let item = document.getElementById(id.replace("#", ""))
            let itemTop = item.offsetTop
            if (document.documentElement.scrollTop >= itemTop - 50 && document.documentElement.scrollTop <= (itemTop + item.offsetHeight / 2)) {
                targetId = id;
            }
        }
        if (targetId) {

            let linkDom = document.getElementById(`nav-item-${targetId.replace("#", "")}`)
            let indicatorW = linkDom.offsetWidth;
            let left = linkDom.offsetLeft;
            this.setState({
                width: indicatorW,
                left,
                showNavBlock: true
            })
            // window.location.hash = targetId
            // location.hash = targetId

        } else {
            let item = document.getElementById(nav[0].targetId.replace("#", ""))
            if (item && document.documentElement.scrollTop < item.offsetTop) {
                this.setState({
                    showNavBlock: false
                })
            }
        }
    }
    beginScroll = () => {
        console.log(`开始滚动`)
    }

    endScroll = () => {
        console.log(`结束滚动`)
    }

    showDrawer = () => {
        this.setState({
            drawerVisible: true,
        });
    };

    onClose = () => {
        this.setState({
            drawerVisible: false,
        });
    };

    render() {
        return (
            <div className={`comp-nav`}>

                <div className={`fixed-content`}>
                    <BackTop visibilityHeight="-100">
                        <div className="logo">
                            <Img src="https://img.uigreat.com/pxfer/logo.png"></Img>
                        </div>
                    </BackTop>
                    <nav ref={this.navRef} >
                        {
                            nav.map((item, index) =>
                                <div className={`nav-item nav-item-${item.targetId.replace("#", '')}`} id={`nav-item-${item.targetId.replace("#", '')}`} key={index} onClick={e => {
                                    document.querySelector(item.targetId).scrollIntoView({ behavior: 'smooth' });
                                }} ><div>{item.title}</div></div>
                            )
                        }
                        <div className={`indicator-block`} hidden={!this.state.showNavBlock} ref={this.indicatorRef} style={{ width: this.state.width, left: this.state.left }} />
                    </nav>
                    <MenuFoldOutlined className="menu" onClick={this.showDrawer} />
                    <Drawer
                        placement="right"
                        closable={true}
                        onClose={this.onClose}
                        visible={this.state.drawerVisible}
                        bodyStyle={{ backgroundColor: "#34393e" }}
                        width={150}
                    >
                        <div>
                            {
                                nav.map((item, index) =>
                                    <div className={`nav-item nav-item-${item.targetId.replace("#", '')}`} id={`nav-item-${item.targetId.replace("#", '')}`} key={index} onClick={e => {
                                        document.querySelector(item.targetId).scrollIntoView({ behavior: 'smooth' })
                                        this.onClose()
                                    }} ><div>{item.title}</div></div>
                                )
                            }
                        </div>
                    </Drawer>
                </div>

            </div>
        )
    }
}
export default Nav

