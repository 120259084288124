import React from "react"
import './banner.scss'
import Img from "./img"
import { Row, Col, Modal } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import Slider from "react-slick"
import Pagination from "./pagination"

const works = [{
  title: "运营03期",
  imgSrc: "https://img.uigreat.com/pxfer/banner1.jpg"
}, {
  title: "50期",
  imgSrc: "https://img.uigreat.com/pxfer/banner2.jpg"
}, {
  title: "49期",
  imgSrc: "https://img.uigreat.com/pxfer/banner3.jpg"
}, {
  title: "48期",
  imgSrc: "https://img.uigreat.com/pxfer/banner4.jpg"
}]

let settings = {
  dots: false,
  infinite: true,
  lazyLoad: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  initialSlide: 0,
  centerPadding: 0,
  arrows: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      }
    }
  ]
};

class Banner extends React.Component {
  constructor(props) {
    super(props)
    this.sliderRef = React.createRef();
    this.imgRef = React.createRef()
  }

  state = {
    current: 1,
    showWorkPreview: false,
    currentPreview: 0,
  }

  componentDidMount() {

  }


  render() {
    return (
      <div className={`comp-banner`}>
        <Row type="flex" justify="space-between">
          <Col xs={24} md={12} className="stu-slider-left">

            <div className="banner-panel">
              <Img className="banner-item" src="https://img.uigreat.com/pxfer/banner-item1.png" data-sal data-sal-delay="300" data-sal-duration="500" data-sal-easing="ease"></Img>
              <Img className="banner-item" src="https://img.uigreat.com/pxfer/banner-item2.png" data-sal data-sal-delay="400" data-sal-duration="500" data-sal-easing="ease"></Img>
              <Img className="banner-item" src="https://img.uigreat.com/pxfer/banner-item3.png" data-sal data-sal-delay="500" data-sal-duration="500" data-sal-easing="ease"></Img>
              <div className="banner-title-panel">
                <Img src="https://img.uigreat.com/pxfer/banner-title1.png" style={{ position: "relative", zIndex: "2" }} data-sal="slide-up" data-sal-delay="100" data-sal-duration="1000" data-sal-easing="ease"></Img>
                <Img className="main-title" src="https://img.uigreat.com/pxfer/banner-title2.png" style={{ position: "relative", zIndex: "2" }} data-sal="slide-up" data-sal-delay="200" data-sal-duration="1000" data-sal-easing="ease"></Img>
                <Img src="https://img.uigreat.com/pxfer/banner-title4.png" style={{ position: "relative", zIndex: "2" }} data-sal="slide-up" data-sal-delay="300" data-sal-duration="1000" data-sal-easing="ease"></Img>
              </div>
              <div className="banner-btn" onClick={() => {
                document.querySelector("#outline").scrollIntoView({ behavior: 'smooth' });

              }} data-sal="slide-up" data-sal-delay="100" data-sal-duration="1000" data-sal-easing="ease">
                <div className="banner-btn-text" data-text="了解详情">了解详情</div>
                <div className="banner-btn-item">
                  <div className="item-line"></div>
                  <div className="item-circle"></div>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} md={12} className="stu-slider-right">
            <Slider className={`slider`}
              {...settings}
              ref={this.sliderRef}
              afterChange={current => {
                this.setState({
                  current: current + 1
                })


              }}
            >
              {
                works.map((item, index) =>
                  <div className={`slider-item ${this.state.current}`} key={index} onMouseDown={() => {
                    this.canShow = true;
                    document.onmousemove = () => {
                      this.canShow = false
                    }

                  }} onMouseUp={() => {
                    if (this.canShow) {
                      this.setState({
                        showWorkPreview: true,
                        currentPreview: index
                      })
                      setTimeout(() => {
                        this.setState({
                          showClose: true
                        })
                      }, 300)
                    }

                  }} onTouchStart={() => {
                    this.canShow = true
                    document.ontouchmove = () => {
                      this.canShow = false
                    }

                  }} onTouchEnd={() => {

                    if (this.canShow) {

                      this.setState({
                        showWorkPreview: true,
                        currentPreview: index
                      })
                      setTimeout(() => {
                        this.setState({
                          showClose: true
                        })
                      }, 300)
                    }
                  }} >
                    <div className={`stu-item`} >
                      <div className={`title`}>{item.title}</div>
                      <img src={item.imgSrc} draggable={false} alt={""} />
                      <div className={'mask'}></div>
                    </div>

                  </div>
                )
              }
            </Slider>





            <Pagination className={`banner-pagination`} current={this.state.current || 1} total={works.length} leftArrowSrc={'https://img.uigreat.com/pxfer/page-left-arrow.png'} rightArrowSrc={'https://img.uigreat.com/pxfer/page-right-arrow.png'} onNext={() => {
              this.sliderRef.current.slickNext()
            }} onPrev={() => {
              this.sliderRef.current.slickPrev()
            }} />
            <div className="banner-pagination-text">
              <Img src="https://img.uigreat.com/pxfer/banner-pagination-text.png"></Img>
            </div>
          </Col>
        </Row>

        <Modal
          title={null}
          visible={this.state.showWorkPreview}
          onCancel={() => {
            this.setState({
              showWorkPreview: false,
              showClose: false
            })
          }}
          footer={null}
          width={"100%"}
          centered
          className={`banner-preview-modal`}
          destroyOnClose={true}
          closeIcon={null}

        >
          <div className={`img-box`} style={{ height: document.body.clientHeight }} onClick={() => {
            this.setState({
              showClose: false
            }, () => {
              this.setState({
                showWorkPreview: false,
                showClose: false
              })
            })

          }}>
            <CloseOutlined className={`close-btn`} hidden={!this.state.showClose} />


            <img src={works[this.state.currentPreview].imgSrc} ref={this.imgRef} alt={""} onLoad={() => {
              let img = this.imgRef.current
              let imgWidth = img.width;
              let imgHeight = img.height;
              let radio = imgWidth / imgHeight;
              let maxHeight = document.body.clientHeight - 100;
              let maxWidth = document.body.clientWidth - 100;
              // console.log(`maxWidth,maxHeight`, maxWidth, maxHeight, radio)
              if (maxWidth / radio > maxHeight) {
                img.width = maxHeight * radio
                img.height = maxHeight;

              } else {
                img.width = maxWidth
                img.height = maxWidth / radio
              }
              // console.log(`width,height`, img.width, img.height)
            }} onClick={e => { e.stopPropagation() }
            } />
          </div>

        </Modal>
      </div>
    )
  }

}
export default Banner