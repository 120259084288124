import React from "react"
import Img from "./img"
import "./indicatorSlider.scss"

class IndicatorSlider extends React.Component {
  constructor(props) {
    super(props)
    this.sliderRef = React.createRef()
    this.sliderBarRef = React.createRef()
  }

  state = {
    left:4
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

  }

  scrollTo  = (value)=>{
    if(!this.startMove){
      let sliderWidth = this.sliderRef.current.offsetWidth;
      let barWidth = this.sliderBarRef.current.offsetWidth;
      this.setState({
        left: value * (barWidth - 8 - sliderWidth) + 4
      })
    }

  }


  startMove = false;

  render() {

    return (
      <div className={`comp-indicator-slider ${this.props.className || ""}`} ref={this.sliderBarRef}>
        <div className={`slider-block`} ref={this.sliderRef} style={{left: this.state.left}} onMouseDown={e=>{
            this.startMove = true
          e.preventDefault()

          let { clientX } = e
          // let width = parseFloat(editorStyle.width)
          // let height = parseFloat(editorStyle.height)
          let startLeft = this.state.left;
            let sliderWidth = this.sliderRef.current.offsetWidth;
            let barWidth = this.sliderBarRef.current.offsetWidth;
          document.body.onmousemove = (e) => {
            let moveX = startLeft +    e.clientX - clientX
            if(moveX <= 4){
              moveX = 4
            }
            if(moveX +  sliderWidth >= barWidth - 4){
              moveX = barWidth - 4 - sliderWidth;
            }
            if(this.props.onScroll){
               this.props.onScroll((moveX - 4) / (barWidth - 8 - sliderWidth) )
            }
            this.setState({
              left:moveX
            })
          }
          document.body.onmouseup =  e =>{
            document.body.onmousemove = null
            this.startMove = false
          }
        }} onTouchStart={e=>{
          e.preventDefault()
          this.startMove = true

          let { clientX } = e.touches[0]

          // let width = parseFloat(editorStyle.width)
          // let height = parseFloat(editorStyle.height)
          let startLeft = this.state.left;
          let sliderWidth = this.sliderRef.current.offsetWidth;
          let barWidth = this.sliderBarRef.current.offsetWidth;
          document.body.ontouchmove = (event) => {
            let touch =  event.touches[0];
            let moveX = startLeft +    touch.clientX - clientX
            if(moveX <= 4){
              moveX = 4
            }
            if(moveX +  sliderWidth >= barWidth - 4){
              moveX = barWidth - 4 - sliderWidth;
            }
            if(this.props.onScroll){
              this.props.onScroll((moveX - 4) / (barWidth - 8 - sliderWidth) )
            }
            this.setState({
              left:moveX
            })
          }
          document.body.ontouchend =  e =>{
            document.body.ontouchmove = null
            this.startMove = false
          }
        }} onTouchEnd={()=>{
          this.startMove = false;
        }} >
          <Img src="https://img.uigreat.com/pxfer/slider-block.png" draggable={true} />
        </div>
      </div>
    )
  }

}

export default IndicatorSlider;