import React from "react";
import { Row, Col } from "antd";
import "./course-details.scss";
import WeekCard from "./week-card";
export default (props) => (
  <Row type="flex" justify="center">
    <Col xs={24} md={8}>
      <div className="detail-month">
        <div
          className="month-top"
          data-sal="slide-up"
          data-sal-duration="1000"
          data-sal-easing="ease"
        >
          <div>
            <div className="title">第一个月</div>
            <div className="subtitle">#交互篇</div>
          </div>
          <div className="software">
            <img src="https://img.uigreat.com/pxfer/ae.png" alt="" />
            <img src="https://cdn.uigreat.com/b696af84-e004-4620-bd5a-6de2f5717f94.png" alt="" />
          </div>
        </div>
        <WeekCard title="Week 01" color="blue">
          UI行业认知 工作流 交互原型(FIGMA)
        </WeekCard>
        <WeekCard title="Week 02" color="blue">
          UI界面规范+基础界面代练+界面元素 入门(Figma/Sketch)
        </WeekCard>
        <WeekCard title="Week 03" color="blue">
          产品动态视觉表现与工作落地(Ae)
        </WeekCard>
        <WeekCard title="Week 04" color="blue">
          最终作品呈现与作品集 showreel(Ae)
        </WeekCard>
      </div>
    </Col>
    <Col xs={24} md={8}>
      <div className="detail-month">
        <div
          className="month-top"
          data-sal="slide-up"
          data-sal-duration="1000"
          data-sal-easing="ease"
        >
          <div>
            <div className="title">第二个月</div>
            <div className="subtitle">#实现篇 #运营篇</div>
          </div>
          <div className="software">
            <img src="https://cdn.uigreat.com/04d31696-d424-4aa8-b4ec-fc268b524e2d.png" alt="" />
            <img src="https://img.uigreat.com/pxfer/ai.png" alt="" />
            {/* <img src="https://img.uigreat.com/pxfer/sketch.png" alt="" /> */}
          </div>
        </div>
        <WeekCard title="Week 05" color="orange">
          交互设计、产品思维、高保真实现 (Principle/飞书)
        </WeekCard>
        <WeekCard title="Week 06" color="orange">
          Keynote入门与进阶、品牌设计 (Keynote)
        </WeekCard>
        <WeekCard title="Week 07" color="orange">
          AIGC与UI产品设计结合快速产出界面 (Figma/ Sketch/ AIGC)
        </WeekCard>
        <WeekCard title="Week 08" color="orange">
          UI产品APP的品牌视觉升级 (illustrator/Figma/Sketch/ AIGC)
        </WeekCard>
      </div>
    </Col>
    <Col xs={24} md={8}>
      <div className="detail-month">
        <div
          className="month-top"
          data-sal="slide-up"
          data-sal-duration="1000"
          data-sal-easing="ease"
        >
          <div>
            <div className="title">第三个月</div>
            <div className="subtitle">#作品集篇</div>
          </div>
          <div className="software">
            <img src="https://img.uigreat.com/pxfer/keynote.png" alt="" />
            <img src="https://cdn.uigreat.com/b696af84-e004-4620-bd5a-6de2f5717f94.png" alt="" />
          </div>
        </div>
        <WeekCard title="Week 09" color="green">
          结合AIGC批量产出品牌字体库 (illustrator/Photoshop/AIGC)
        </WeekCard>
        <WeekCard title="Week 10" color="green">
          app产品组件化设计用AIGC快速落地 (ilustrator/Photoshop/AIGC)
        </WeekCard>
        <WeekCard title="Week 11" color="green">
          作品集核心思路拆解、行业动态 (Keynote+figma)
        </WeekCard>
        <WeekCard title="Week 12" color="green">
          就业指导、面试技巧和职业规划 (Keynote)
        </WeekCard>
      </div>
    </Col>
  </Row>
);
