import React from "react"
import Nav from "./components/nav"
import Banner from "./components/banner"
import Panel from "./components/panel"
import PanelTitle from "./components/panel-title"
import CourseDetail from "./components/course-details"
import Section3 from "./components/section3"
import Section6 from "./components/section6"
import Section7 from "./components/section7"
import Section8 from "./components/section8"
import { Row, Col, Divider } from "antd"
import "normalize.css"
import "./index.scss"
import MVPSlider from "./components/MVPSlider"
import TeacherSlider from './components/TeacherSlider'
import StudentSlider from "./components/StudentSlider"
import ScrollView from "./components/ScrollView"
import smoothscroll from 'smoothscroll-polyfill';
import 'sal.js/dist/sal.css';
import sal from "sal.js";

class App extends React.Component {

    state = {
        show: false
    }



    componentDidMount() {

        smoothscroll.polyfill();

        setTimeout(() => {
            sal({
                threshold: 0.4,
                once: true
                // once: false
            });
        }, 1000);

    }
    componentDidUpdate() {

    }

    componentWillUnmount() {
        // window.location.reload(true);

    }
    render() {

        return (
            <div className="container">
                <Nav />
                <Banner />
                <Panel id="outline" style={{ marginBottom: "165px" }}>
                    <PanelTitle img="https://img.uigreat.com/pxfer/v01.png" enTitle="Course Details" mainTitle="高端设计课程大纲" subTitle={<span>让你从一个“挑战者”到“卫冕者”的课程<br />周期三个月课程(12周、84天)从设计提高到进阶，每个月对设计都有新的认识，<br />为一线互联网公司量身打造</span>}></PanelTitle>
                    <CourseDetail />
                </Panel>
                <Panel id="contact" bgcolor={"#26282B"}>
                    <Row type="flex" justify="center">
                        <Col xs={24} md={12}>
                            <PanelTitle align="left" color="white" img="https://img.uigreat.com/pxfer/v02.png" enTitle="Apply Info"
                                mainTitle={<span>报名信息</span>}></PanelTitle>
                            <ul className="contact">
                                <li data-sal="slide-up"
                                    data-sal-duration="1000"
                                    data-sal-easing="ease">
                                    <img src="https://img.uigreat.com/pxfer/icon1.png" alt="" />
                                    <div className="contact-title">
                                        学校地址：
                                    </div>
                                    <div className="contact-content">
                                        北京市东城区崇文门外大街磁器口地铁站A口新成文化大厦1011
                                    </div>
                                </li>
                                <li data-sal="slide-up"
                                    data-sal-duration="1000"
                                    data-sal-easing="ease">
                                    <img src="https://img.uigreat.com/pxfer/icon2.png" alt="" />
                                    <div className="contact-title">
                                        咨询电话：
                                    </div>
                                    <div className="contact-content">
                                        +010 67011169
                                    </div>
                                </li>
                                <li data-sal="slide-up"
                                    data-sal-duration="1000"
                                    data-sal-easing="ease">
                                    <img src="https://img.uigreat.com/pxfer/icon3.png" alt="" />
                                    <div className="contact-title">
                                        咨询时间：
                                    </div>
                                    <div className="contact-content">
                                        咨询时间工作日9：30-18：30
                                        预约试听请提前电话或QQ咨询
                                    </div>
                                </li>
                                <li data-sal="slide-up"
                                    data-sal-duration="1000"
                                    data-sal-easing="ease">
                                    <img src="https://img.uigreat.com/pxfer/icon4.png" alt="" />
                                    <div className="contact-title">
                                        咨询QQ：
                                    </div>
                                    <div className="contact-content">
                                        &nbsp;&nbsp;
                                        <a style={{ color: "#a7a8aa", textDecoration: "underline" }} target="_blank" href="http://wpa.qq.com/msgrd?v=3&uin=2402233530&site=qq&menu=yes" rel="noopener noreferrer">2402233530 Amy</a>（唯一官方咨询渠道）
                                    </div>
                                </li>
                                <li data-sal="slide-up"
                                    data-sal-duration="1000"
                                    data-sal-easing="ease">
                                    <img src="https://img.uigreat.com/pxfer/icon4.png" alt="" />
                                    <div className="contact-title">
                                        咨询微信：
                                    </div>
                                    <div className="contact-content">
                                        <div>洛洛老师（唯一官方咨询渠道）</div>
                                        <div>微信号：zhailolo</div>
                                        <img width="150" src="https://img.uigreat.com/pxfer/lolo.png" alt="" />
                                    </div>
                                </li>
                                <li data-sal="slide-up"
                                    data-sal-duration="1000"
                                    data-sal-easing="ease">
                                    <img src="https://img.uigreat.com/pxfer/icon4.png" alt="" />
                                    <div className="contact-title">
                                        咨询微信：
                                    </div>
                                    <div className="contact-content">
                                        <div>晶晶老师（唯一官方咨询渠道）</div>
                                        <img width="150" src="https://img.uigreat.com/pxfer/jingjing.png" alt="" />
                                    </div>
                                </li>
                            </ul>
                        </Col>
                        <Col xs={24} md={12}>
                            <div className="contact-building" data-sal="slide-up"
                                data-sal-duration="1000"
                                data-sal-easing="ease">
                                <img src="https://img.uigreat.com/pxfer/building.png" alt="" />
                            </div>
                        </Col>
                    </Row>
                </Panel>
                <Panel id="go">
                    <Row type="flex" justify="center">
                        <Col xs={{ span: 24, order: 2 }} md={{ span: 16, order: 1 }} >
                            <Section3></Section3>
                        </Col>
                        <Col xs={{ span: 24, order: 1 }} md={{ span: 8, order: 2 }} className="flex-center">
                            <PanelTitle align="left" img="https://img.uigreat.com/pxfer/v03.png" enTitle="Students go" mainTitle="学员去向"
                                subTitle="学员遍布一线互联网公司大厂，由于公司众多，左图只放一部分。许多公司已经成为“像素范儿根据地”毫不夸张，众多同学在同一个公司甚至同一个团队，有的时候你会不经意发现，旁边的设计师跟你一个群！" style={{ paddingLeft: "80px" }} />
                        </Col>
                    </Row>
                </Panel>
                <Panel id="achieve">
                    <PanelTitle style={{ paddingBottom: "0" }} img="https://img.uigreat.com/pxfer/v04.png" enTitle="Achievements" mainTitle="学员成就" subTitle={<span>目前为止在全球设计领域，像素范儿学员最高成就：12+Behance标签推荐、<br />Dribbble 5000+粉丝20人、11个站酷黑Z。<br />D这些数字还在增长，有可能在未来的某天，你也成为像素范儿学员成就拼图里的一员，加油吧！</span>} />
                    <StudentSlider />
                </Panel>
                <Panel id="update">
                    <Row type="flex" justify="center">
                        <Col xs={24} md={12}>
                            <PanelTitle align="left" img="https://img.uigreat.com/pxfer/v05.png" enTitle="Update direction" mainTitle="永不停息，课程更新方向"
                                subTitle={<span>我们一直保持互联网行业学术研究最前沿。这些都是国际领域目前最火的风口，由专业的师资团队去研究挖掘，汇总成体系融到课程中。我们的更新课程从不间断，为的就是能让同学站在行业风口的顶端。</span>} style={{ paddingTop: "100px" }}></PanelTitle>
                        </Col>
                        <Col xs={24} md={12}>
                            <ScrollView />
                        </Col>
                    </Row>
                </Panel>
                <Panel id="circle" bgcolor={"#26282B"} style={{ marginBottom: "100px" }}>
                    <PanelTitle color="white" img="https://img.uigreat.com/pxfer/v06.png" enTitle="Choose School" mainTitle="选择学校，更是选择生活"
                        subTitle={<span>在北京或世界各地，毕业生就是像素范儿引以为傲的财富。像素范儿已经形成了成熟的设计圈子文化，我们有8个微信满群，在里面可以认识到众多毕业去到大厂的师兄师姐，他们会有众多资源在里面分发：私活、内推、经验等等，像素范儿已经成为了一个真正的设计大家庭</span>} />
                    <Section6></Section6>
                </Panel>
                <Panel id="matrix" bgcolor={"#26282B"}>
                    <PanelTitle style={{ paddingBottom: "0px" }} color="white" img="https://img.uigreat.com/pxfer/v07.png" enTitle="More Courses" mainTitle="像素范儿矩阵，一个体系各类课程"
                        subTitle={<span>线下课同学在校期间，免费随便听所有课，听到你烦。
                            独创的课程矩阵，设计领域的其他维度课程，在线下课同学里免费听全部，不仅可以学习在校知识，还能多维度拓宽设计之路</span>} />
                    <Section7></Section7>
                    <a href="https://uigreat.com/course" className="matrix-btn" target="blank">
                        <div className="banner-btn" data-sal="slide-up" data-sal-delay="100" data-sal-duration="1000" data-sal-easing="ease">
                            <div className="banner-btn-text" data-text="立即看课">立即看课</div>
                            <div className="banner-btn-item">
                                <div className="item-line"></div>
                                <div className="item-circle"></div>
                            </div>
                        </div>
                    </a>
                </Panel>
                <Panel id="plan" style={{ marginBottom: "100px" }}>
                    <Row type="flex" justify="center">
                        <Col xs={{ span: 24, order: 2 }} md={{ span: 12, order: 1 }} className="flex-center">
                            <Section8></Section8>
                        </Col>
                        <Col xs={{ span: 24, order: 1 }} md={{ span: 12, order: 2 }} className="flex-center">
                            <PanelTitle align="left" style={{ paddingLeft: "80px" }} img="https://img.uigreat.com/pxfer/v08.png" enTitle="Regrowth plan" mainTitle="授人以鱼，更授人以渔"
                                subTitle={<span>像素范儿毕业再成长计划：抖音大V成长计划、壁纸君培养计划、线上课计划、总监培养计划。这些计划是在设计职场道路之后的又一提高项，旨在全面的提高核心竞争力，个人自媒体全面挖掘，个人自带加持，真正做到不看公司脸色。</span>} />
                        </Col>
                    </Row>
                </Panel>
                <Panel id="big">
                    <Row type="flex" justify="center">
                        <Col span={24}>
                            <PanelTitle style={{ paddingBottom: "0" }} align="center" img="https://img.uigreat.com/pxfer/v09.png" enTitle="Outstanding talents" mainTitle="你知道的大神都来自像素范儿"
                                subTitle="跟大神零距离交流经验：真正手把手、脸贴脸那种" />
                            <MVPSlider />
                        </Col>
                    </Row>
                </Panel>
                <Panel id="lector" className={"section-10"} style={{ paddingTop: 0 }}>
                    <PanelTitle align="left" className={`section-10-panel-title`} img="https://img.uigreat.com/pxfer/v10.png" enTitle="main lecturer" mainTitle={<div className="section-10-title">主讲授课老师<div>(总部)</div></div>} />
                    <TeacherSlider />
                </Panel>
                <Panel style={{ paddingTop: 0 }} bgcolor={"#26282B"}>
                    <div className="footer">
                        <a style={{ color: "#ffffff" }} href="https://uigreat.com/" target="_blank" rel="noopener noreferrer">优阁网-UIGREAT</a>
                        <a style={{ color: "#ffffff", paddingLeft: "10px", paddingRight: "10px" }} href="http://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">京ICP备15031701号-2</a>
                        <Divider type="vertical" />
                        <a style={{ color: "#ffffff", paddingLeft: "10px" }} href="https://design.uigreat.com/" target="_blank" rel="noopener noreferrer">运营设计全能提升班</a>
                    </div>
                </Panel>
            </div>
        )
    }


}
export default App