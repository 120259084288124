import React from "react"
import { Row, Col } from 'antd';
import Img from "./img"
import "./section6.scss"
class Section6 extends React.Component {
    // constructor(props) {
    //     super(props)
    // }
    render() {
        return (
            <Row type="flex" justify="center">
                <Col xs={12} md={6} className="sec6-col">
                    <div className="phone"  data-sal="slide-up" data-sal-duration="2000" data-sal-easing="ease">
                        <Img src="https://img.uigreat.com/pxfer/phone1.png" alt="" />
                    </div>
                    <div className="phone" data-sal="slide-up" data-sal-duration="2000" data-sal-easing="ease">
                        <Img src="https://img.uigreat.com/pxfer/phone2.png" alt="" />
                    </div>
                </Col>
                <Col xs={12} md={6} className="sec6-col">
                    <div className="phone"  style={{ marginTop: "0px" }} data-sal="fade" data-sal-duration="2000" data-sal-easing="ease">
                        <Img src="https://img.uigreat.com/pxfer/phone3.png" alt="" />
                    </div>
                    <div className="phone" data-sal="fade" data-sal-duration="2000" data-sal-easing="ease">
                        <Img src="https://img.uigreat.com/pxfer/phone4.png" alt="" />
                    </div>
                    <div className="talk2" data-sal="slide-up" data-sal-duration="2000" data-sal-easing="ease">
                        <Img src="https://img.uigreat.com/pxfer/talk2.png"></Img>
                    </div>
                    <div className="talk3" style={{marginTop:"50px",marginLeft:"-280px"}} data-sal="slide-up" data-sal-duration="2000" data-sal-easing="ease">
                        <Img src="https://img.uigreat.com/pxfer/talk3.png"></Img>
                    </div>
                </Col>
                <Col xs={12} md={6} className="sec6-col">
                    <div className="talk" style={{marginTop:"50px"}} data-sal="slide-up" data-sal-duration="2000" data-sal-easing="ease">
                        <Img src="https://img.uigreat.com/pxfer/talk1.png"></Img>
                    </div>
                    <div className="phone" data-sal="slide-up" data-sal-duration="2000" data-sal-easing="ease">
                        <Img src="https://img.uigreat.com/pxfer/phone5.png" alt="" />
                    </div>
                    <div className="phone" data-sal="slide-up" data-sal-duration="2000" data-sal-easing="ease">
                        <Img src="https://img.uigreat.com/pxfer/phone6.png" alt="" />
                    </div>
                </Col>
                <Col xs={12} md={6} className="sec6-col">
                    <div className="phone" style={{ marginTop: "0px" }}  data-sal="fade" data-sal-duration="2000" data-sal-easing="ease">
                        <Img src="https://img.uigreat.com/pxfer/phone7.png" alt="" />
                    </div>
                    <div className="phone" data-sal="fade" data-sal-duration="2000" data-sal-easing="ease">
                        <Img src="https://img.uigreat.com/pxfer/phone8.png" alt="" />
                    </div>
                </Col>
            </Row>
        )
    }
}
export default Section6

