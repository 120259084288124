import React from "react"
class Img extends React.Component {
    constructor(props) {
        super(props)
        this.imgref = React.createRef()
    }
    render() {
        return (<img  data-sal={this.props["data-sal"]}
        data-sal-duration={this.props["data-sal-duration"]}
        data-sal-easing={this.props["data-sal-easing"]} data-sal-delay={this.props["data-sal-delay"]} className={`${this.props.className || ""}`} draggable={this.props.draggable || false} ref={this.imgref} 
        
        onLoad={()=>{

            let vImage = new Image();
            vImage.onload = ()=>{
                let img = this.imgref.current
                if(window.outerWidth < 768){
                    img.width = vImage.width / 4;
                }else{
                    img.width = vImage.width / 2;
                }
            }
            vImage.src = this.props.src;
          
        }}  src={this.props.src} style={this.props.style || {}} alt=""/>)
    }
}
export default Img

