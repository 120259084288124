import React from "react"
import Img from "./img"
import * as moment from 'moment'
import "./section8.scss"



class Section8 extends React.Component {
    state = {
        hdeg: 0,
        mmdeg: 0,
        rolltime: 0
    }

    now() {
        let h = moment().format('h');
        let mm = moment().format('mm');
        let ss = moment().format('ss');
        let ssdeg = ss / 60 * 360;
        let mmdeg = mm / 60 * 360 + (ssdeg / 60);
        let hdeg = h / 12 * 360 + (mmdeg / 12);
        this.setState({
            hdeg: hdeg,
            mmdeg: mmdeg,
        })
    }

    componentDidMount() {
        let h = moment().format('h');
        let mm = moment().format('mm');
        let ss = moment().format('ss');
        let ssdeg = ss / 60 * 360;
        let mmdeg = mm / 60 * 360 + (ssdeg / 60);
        let hdeg = h / 12 * 360 + (mmdeg / 12);
        let rolltime = mmdeg / 360 * 1.2;
        this.setState({
            hdeg: hdeg - 30,
            mmdeg: mmdeg - 360,
            rolltime: rolltime
        })
        this.refs.clock.addEventListener('sal:in', ({ detail }) => {
            this.now()
            setInterval(() => {
                this.now()
            }, 10000)
        });
    }

    render() {
        return (
            <div className="clock-panel">
                <div className="clock" ref="clock" data-sal="slide-up" data-sal-delay="100" data-sal-duration="1000" data-sal-easing="ease">
                    <div className="clock-bg">
                        {/* <div className="clock-dial" style={{transform:[`rotate(0deg)`]}}></div>
                        <div className="clock-dial" style={{transform:[`rotate(45deg)`]}}></div>
                        <div className="clock-dial" style={{transform:[`rotate(90deg)`]}}></div>
                        <div className="clock-dial" style={{transform:[`rotate(135deg)`]}}></div>
                        <div className="clock-dial" style={{transform:[`rotate(180deg)`]}}></div>
                        <div className="clock-dial" style={{transform:[`rotate(225deg)`]}}></div>
                        <div className="clock-dial" style={{transform:[`rotate(270deg)`]}}></div>
                        <div className="clock-dial" style={{transform:[`rotate(315deg)`]}}></div> */}
                        <Img className="clock-dial" src="https://img.uigreat.com/pxfer/dial.png"></Img>

                    </div>
                    <div className="needle">
                        <div className="base"></div>
                        <div className="hour" style={{ transform: [`rotate(${this.state.hdeg}deg)`] }}></div>
                        <div className="minute" style={{ transform: [`rotate(${this.state.mmdeg}deg)`] }}></div>
                    </div>
                    <div className="needle needle1">
                        <div className="base"></div>
                        <div className="hour" style={{ transform: [`rotate(${this.state.hdeg}deg)`] }}></div>
                        <div className="minute" style={{ transform: [`rotate(${this.state.mmdeg}deg)`] }}></div>
                    </div>
                    {/* <Img className="needle" src="https://img.uigreat.com/pxfer/needle.png" data-sal  data-sal-duration="300" data-sal-easing="ease"></Img> */}
                    <div className="fr-bg champion" style={{ transitionDelay: `${(0.15 - this.state.rolltime < 0) ? 0.15 - this.state.rolltime + 1.2 : 0.15 - this.state.rolltime}s` }}>
                        <Img src="https://img.uigreat.com/pxfer/champion.png"></Img>
                    </div>
                    <div className="fr-text champion-text" style={{ transitionDelay: `${(0.15 - this.state.rolltime < 0) ? 0.15 - this.state.rolltime + 1.2 : 0.15 - this.state.rolltime}s` }}>壁纸君</div>
                    <div className="fr-bg tl" style={{ transitionDelay: `${(0.45 - this.state.rolltime < 0) ? 0.45 - this.state.rolltime + 1.2 : 0.45 - this.state.rolltime}s` }}>
                        <Img src="https://img.uigreat.com/pxfer/tl.png"></Img>
                    </div>
                    <div className="fr-text tl-text" style={{ transitionDelay: `${(0.45 - this.state.rolltime < 0) ? 0.45 - this.state.rolltime + 1.2 : 0.45 - this.state.rolltime}s` }}>总监</div>
                    <div className="fr-bg online" style={{ transitionDelay: `${(0.75 - this.state.rolltime < 0) ? 0.75 - this.state.rolltime + 1.2 : 0.75 - this.state.rolltime}s` }}>
                        <Img src="https://img.uigreat.com/pxfer/online.png"></Img>
                    </div>
                    <div className="fr-text online-text" style={{ transitionDelay: `${(0.75 - this.state.rolltime < 0) ? 0.75 - this.state.rolltime + 1.2 : 0.75 - this.state.rolltime}s` }}>线上课</div>
                    <div className="fr-bg douyin" style={{ transitionDelay: `${(1.05 - this.state.rolltime < 0) ? 1.05 - this.state.rolltime + 1.2 : 1.05 - this.state.rolltime}s` }}>
                        <Img src="https://img.uigreat.com/pxfer/douyin.png"></Img>
                    </div>
                    <div className="fr-text douyin-text" style={{ transitionDelay: `${(1.05 - this.state.rolltime < 0) ? 1.05 - this.state.rolltime + 1.2 : 1.05 - this.state.rolltime}s` }}>抖音</div>
                </div>
            </div>
        )
    }
}
export default Section8

