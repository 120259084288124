import React from "react"
import Img from "./img"
import "./section7.scss"

class Section7 extends React.Component {
    // constructor(props) {
    //     super(props)
    // }
    state = {
        shift: 0
        // bg1: 0
    }
    componentDidMount() {
        this.setState({
            bg1: this.refs.bg1.getBoundingClientRect().width / 2,
            bg2: this.refs.bg2.getBoundingClientRect().width / 2,
            bg3: this.refs.bg3.getBoundingClientRect().width / 2
        })
        let circleCenter = this.refs.bg1.getBoundingClientRect().left + this.refs.bg1.getBoundingClientRect().width / 2;
        let shift = 0;
        document.onmousemove = (e) => {
            shift = e.clientX - circleCenter
            this.setState({
                shift: shift
            })
        }

    }
    render() {
        return (
            <div className="matrix" data-sal="slide-up" data-sal-delay="100" data-sal-duration="1000" data-sal-easing="ease">
                <div className="matrix-bg matrix-bg-1" ref="bg1" style={{ marginLeft: `${-this.state.bg1 - this.state.shift * 0.06}px` }}>
                    <div className="matrix-bg-title">外围(线下)</div>
                    <div className="matrix-bg-subtitle">periphery</div>
                </div>
                <div className="matrix-bg matrix-bg-2" ref="bg2" style={{ marginLeft: `${-this.state.bg2 - this.state.shift * 0.04}px` }}>
                    <div className="matrix-bg-title">合作(线上)</div>
                    <div className="matrix-bg-subtitle">cooperation</div>
                </div>
                <div className="matrix-bg matrix-bg-3" ref="bg3" style={{ marginLeft: `${-this.state.bg3 - this.state.shift * 0.02}px` }}>
                    <div className="matrix-bg-title">内核(线下)</div>
                    <div className="matrix-bg-subtitle">core</div>
                </div>
                <div className="matrix-item matrix-item-1" data-sal="slide-right" data-sal-duration="1000" data-sal-easing="ease">
                    <Img src="https://img.uigreat.com/pxfer/matrix-icon1.png"></Img>
                    <div className="matrix-item-text">
                        <div className="matrix-item-title">内核：像素范儿老师</div>
                        <div className="matrix-item-subtitle">由主讲老师组成线下课体系</div>
                    </div>
                </div>
                <div className="matrix-item matrix-item-2" data-sal="slide-left" data-sal-duration="1000" data-sal-easing="ease">
                    <Img src="https://img.uigreat.com/pxfer/matrix-icon2.png"></Img>
                    <div className="matrix-item-text">
                        <div className="matrix-item-title">合作：像素范儿老师 + 外围老师</div>
                        <div className="matrix-item-subtitle">主讲老师与合作老师 "1+1模式" 组成线上课</div>
                    </div>
                </div>
                <div className="matrix-item matrix-item-3" data-sal="slide-right" data-sal-duration="1000" data-sal-easing="ease">
                    <Img src="https://img.uigreat.com/pxfer/matrix-icon3.png"></Img>
                    <div className="matrix-item-text">
                        <div className="matrix-item-title">外围：像素范儿孵化讲师</div>
                        <div className="matrix-item-subtitle">由像素范儿孵化的讲师，组成<br />线上课程体系</div>
                    </div>
                </div>
                <div className="matrix-fr">
                    <div className="out" style={{ left: "3%", top: "3%" }}>
                        <Img className="out-text" src="https://img.uigreat.com/pxfer/out-text.png"></Img>
                        <Img className="out-dot" src="https://img.uigreat.com/pxfer/out-dot.png"></Img>
                    </div>
                    <div className="out" style={{ left: "50%", top: "-4%" }}>
                        <Img className="out-text" src="https://img.uigreat.com/pxfer/out-text.png"></Img>
                        <Img className="out-dot" src="https://img.uigreat.com/pxfer/out-dot.png"></Img>
                    </div>
                    <div className="out" style={{ right: "18%", top: "30%" }}>
                        <Img className="out-text" src="https://img.uigreat.com/pxfer/out-text.png"></Img>
                        <Img className="out-dot" src="https://img.uigreat.com/pxfer/out-dot.png"></Img>
                    </div>
                    <div className="out" style={{ right: "28%", bottom: "11%" }}>
                        <Img className="out-text" src="https://img.uigreat.com/pxfer/out-text.png"></Img>
                        <Img className="out-dot" src="https://img.uigreat.com/pxfer/out-dot.png"></Img>
                    </div>
                    <div className="out" style={{ left: "1%", bottom: "20%" }}>
                        <Img className="out-text" src="https://img.uigreat.com/pxfer/out-text.png"></Img>
                        <Img className="out-dot" src="https://img.uigreat.com/pxfer/out-dot.png"></Img>
                    </div>
                    <div className="mid" style={{ left: "-8%", top: "40%" }}>
                        <Img className="mid-text" src="https://img.uigreat.com/pxfer/mid-text.png"></Img>
                        <Img className="mid-dot" src="https://img.uigreat.com/pxfer/mid-dot.png"></Img>
                    </div>
                    <div className="mid" style={{ left: "27%", top: "10%" }}>
                        <Img className="mid-text" src="https://img.uigreat.com/pxfer/mid-text.png"></Img>
                        <Img className="mid-dot" src="https://img.uigreat.com/pxfer/mid-dot.png"></Img>
                    </div>
                    <div className="mid" style={{ right: "64%", top: "59%" }}>
                        <Img className="mid-text" src="https://img.uigreat.com/pxfer/mid-text.png"></Img>
                        <Img className="mid-dot" src="https://img.uigreat.com/pxfer/mid-dot.png"></Img>
                    </div>
                    <div className="in" style={{ right: "54%", top: "37%" }}>
                        <Img className="in-text" src="https://img.uigreat.com/pxfer/in-text.png"></Img>
                        <Img className="in-dot" src="https://img.uigreat.com/pxfer/in-dot.png"></Img>
                    </div>
                </div>
            </div>
        )
    }
}
export default Section7

