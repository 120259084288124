import React from "react"
import Slick from "react-slick"
import Img from "./img"
import { Row, Col } from 'antd'
import "./studentSlider.scss"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const achievements = [{
  src: "https://img.uigreat.com/pxfer/achieve-card1.png",
  tagsrc: "https://img.uigreat.com/pxfer/achieve-card-fr1.png"
}, {
  src: "https://img.uigreat.com/pxfer/achieve-card2.png",
  tagsrc: "https://img.uigreat.com/pxfer/achieve-card-fr2.png"
}, {
  src: "https://img.uigreat.com/pxfer/achieve-card3.png",
  tagsrc: "https://img.uigreat.com/pxfer/achieve-card-fr3.png"
}]
class StudentSlider extends React.Component {
  constructor(props) {
    super(props)
    this.sliderRef = React.createRef()
  }

  state = {
    current: 1,
    changeStatus: 0, // before ,after2
  }

  render() {
    return (
      <div className={`stu-slider`}>
        <div className="v-line"></div>
        <Row type="flex" justify="space-between" data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
          <Col xs={0} md={3}>
            <div className="stu-slider-left">
              <div className="page-num">
                <div className="num-top">0{this.state.current || 1}</div>
                <div className="line"></div>
                <div className="num-bottom">0{achievements.length}</div>
              </div>
            </div>
          </Col>
          <Col xs={24} md={18}>
            <div className="slider-panel">
              <div className={`stu-item-fr ${this.state.changeStatus === 1 ? "change-before" : this.state.changeStatus === 2 ? "change-after" : ""}`}>
                <Img src={achievements[this.state.current - 1].tagsrc} draggable={false} alt={""} />
              </div>
              <Slick className={`slider`}
                fade={false}
                dots={false}
                infinite={true}
                lazyLoad={true}
                speed={500}
                slidesToShow={1}
                slidesToScroll={1}
                initialSlide={0}
                arrows={false}
                ref={this.sliderRef}
                beforeChange={(oldCurrent, current) => {
                  console.log(oldCurrent,current)
                  this.setState({

                    changeStatus: 1
                  })
                }}
                afterChange={current => {
                  this.setState({
                    current: current + 1,
                    changeStatus: 2
                  })
                }}
              >
                {
                  achievements.map((item, index) =>
                    <div className={`slider-item ${this.state.current}`} key={index} >
                      <div className={`stu-item`} >
                        <Img src={item.src} draggable={false} alt={""} />
                      </div>
                    </div>
                  )
                }
              </Slick>
            </div>
          </Col>
          <Col xs={0} md={3}>
            <div className="stu-slider-right">
              <div className="arrow">
                <div className="up" onClick={() => {
                  if (this.sliderRef.current.slickPrev()) {
                    this.sliderRef.current.slickPrev();
                  }
                }}>
                  <Img src="https://img.uigreat.com/pxfer/up.png" />
                </div>
                <div className="down" onClick={() => {
                  if (this.sliderRef.current.slickNext()) {
                    this.sliderRef.current.slickNext();
                  }
                }}>
                  <Img src="https://img.uigreat.com/pxfer/down.png" />
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className="v-arrow">
          <div className="left" onClick={() => {
            if (this.sliderRef.current.slickPrev()) {
              this.sliderRef.current.slickPrev();
            }
          }}>
            <Img src="https://img.uigreat.com/pxfer/up.png" />
          </div>
          <div className="stu-slider-center">
            <div className="page-num">
              <div className="num-top">0{this.state.current || 1}</div>
              <div className="line"></div>
              <div className="num-bottom">0{achievements.length}</div>
            </div>
          </div>
          <div className="right" onClick={() => {
            if (this.sliderRef.current.slickNext()) {
              this.sliderRef.current.slickNext();
            }
          }}>
            <Img src="https://img.uigreat.com/pxfer/down.png" />
          </div>
        </div>
      </div>

    )
  }
}

export default StudentSlider
