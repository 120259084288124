import React from "react"
import "./pagination.scss"


export default props => (
  <div className={`comp-pagination ${props.className || ""}`}  data-sal="slide-up" data-sal-delay="100" data-sal-duration="1000" data-sal-easing="ease">
    <div className={`btn `} onClick={ ()=>{
      if(props.onPrev){
        props.onPrev(props.current - 1);
      }
    }}>
      <img src={props.leftArrowSrc ||  `https://img.uigreat.com/pxfer/arrow-left.png`} draggable={false} alt=""/>
    </div>
    <div className={"page-num"}  >{props.current}{props.current && props.total ? "/":""}{props.total}</div>
    <div className={`btn `} onClick={ ()=>{
      if(props.onNext){
        props.onNext(props.current + 1);
      }
    }}>
      <img src={props.rightArrowSrc || `https://img.uigreat.com/pxfer/arrow-right.png`} draggable={false} alt=""/>
    </div>
  </div>

)