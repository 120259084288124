import React from "react"
import Img from "./img"
import "./section3.scss"
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
class Section3 extends React.Component {
    // constructor(props) {
    //     super(props)
    // }
    render() {
        return (
            <ParallaxProvider>
                <div className="go-panel">
                    <div className="go-cover">
                    </div>
                    <Parallax offsetXMax={-30} offsetXMin={30}>
                    <div className="go-bg">
                        <Img src="https://img.uigreat.com/pxfer/go1.png" alt="" data-sal="slide-right" data-sal-duration="500" data-sal-easing="ease" />
                        <Img src="https://img.uigreat.com/pxfer/go2.png" alt="" data-sal="slide-left" data-sal-duration="700" data-sal-easing="ease" />
                        <Img src="https://img.uigreat.com/pxfer/go3.png" alt="" data-sal="slide-right" data-sal-duration="800" data-sal-easing="ease" />
                        <Img src="https://img.uigreat.com/pxfer/go4.png" alt="" data-sal="slide-left" data-sal-duration="900" data-sal-easing="ease" />
                        <Img src="https://img.uigreat.com/pxfer/go5.png" alt="" data-sal="slide-left" data-sal-duration="700" data-sal-easing="ease" />
                        <Img src="https://img.uigreat.com/pxfer/go6.png" alt="" data-sal="slide-left" data-sal-duration="400" data-sal-easing="ease" />
                        <Img src="https://img.uigreat.com/pxfer/go7.png" alt="" data-sal="slide-right" data-sal-duration="1000" data-sal-easing="ease" />
                        <Img src="https://img.uigreat.com/pxfer/go8.png" alt="" data-sal="slide-right" data-sal-duration="700" data-sal-easing="ease" />
                        <Img src="https://img.uigreat.com/pxfer/go9.png" alt="" data-sal="slide-left" data-sal-duration="600" data-sal-easing="ease" />
                        <Img src="https://img.uigreat.com/pxfer/go10.png" alt="" data-sal="slide-right" data-sal-duration="700" data-sal-easing="ease" />
                        <Img src="https://img.uigreat.com/pxfer/go11.png" alt="" data-sal="slide-left" data-sal-duration="900" data-sal-easing="ease" />
                        <Img src="https://img.uigreat.com/pxfer/go12.png" alt="" data-sal="slide-left" data-sal-duration="500" data-sal-easing="ease" />
                        <Img src="https://img.uigreat.com/pxfer/go13.png" alt="" data-sal="slide-left" data-sal-duration="800" data-sal-easing="ease" />
                        <Img src="https://img.uigreat.com/pxfer/go14.png" alt="" data-sal="slide-right" data-sal-duration="700" data-sal-easing="ease" />
                        <Img src="https://img.uigreat.com/pxfer/go15.png" alt="" data-sal="slide-right" data-sal-duration="1100" data-sal-easing="ease" />
                        <Img src="https://img.uigreat.com/pxfer/go16.png" alt="" data-sal="slide-right" data-sal-duration="700" data-sal-easing="ease" />
                    </div>
                    </Parallax>
                    <Img className="go-bg-img" src="https://img.uigreat.com/pxfer/go-bg.png"></Img>
                </div>
            </ParallaxProvider>
        )
    }
}
export default Section3

