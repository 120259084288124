import React from "react"
import "./scrollView.scss"
import IndicatorSlider from "./IndicatorSlider"
class ScrollView extends React.Component {
  constructor(props) {
    super(props)
    this.scrollContainerRef = React.createRef()
    this.indicatorRef = React.createRef()
  }

  state = {
    contentWidth: 0,
    visibleWidth: 200,
  }

  componentDidMount() {
    let scrollContainer = this.scrollContainerRef.current
    scrollContainer.onscroll = e => {

      let val = this.scrollContainerRef.current.scrollLeft / (this.scrollContainerRef.current.scrollWidth - this.scrollContainerRef.current.offsetWidth)

      this.indicatorRef.current.scrollTo(val)
    }

    this.setState({
      contentWidth: scrollContainer.scrollWidth,
      visibleWidth: this.scrollContainerRef.current.offsetWidth,
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

  }

  render() {
    return (
      <div className={`comp-scroll-view ${this.props.className || ""}`} data-sal="slide-left" data-sal-delay="100" data-sal-duration="1000" data-sal-easing="ease">
        <div className="scroll-cover"></div>
        <div className={`scroll-container`} ref={this.scrollContainerRef}>
          <div className={`scroll-item`}>
            <div className={`item`}>
              <div className={`titles`}>
                <div className={`main-title`}>IOT</div>
                <div className={`subtitle`}>物联网</div>
              </div>

              <div className={`img-box`}>
                <div className={`img-mask`}>
                  <img src={`https://img.uigreat.com/pxfer/section5-01.png`} alt=""/>
                  <div className={`mask-layer`}></div>
                </div>
              </div>
            </div>
          </div>
          <div className={`scroll-item`}>
            <div className={`item`}>
              <div className={`titles`}>
                <div className={`main-title`}>AI</div>
                <div className={`subtitle`}>人工智能</div>
              </div>

              <div className={`img-box`}>


                <div className={`img-mask`}>
                  <img src={`https://img.uigreat.com/pxfer/section5-02.png`} alt="" />
                  <div className={`mask-layer`}></div>
                </div>
              </div>
            </div>
          </div>
          <div className={`scroll-item`}>
            <div className={`item`}>
              <div className={`titles`}>
                <div className={`main-title`}>B端</div>
                <div className={`subtitle`}>物联网</div>
              </div>

              <div className={`img-box`}>


                <div className={`img-mask`}>
                  <img src={`https://img.uigreat.com/pxfer/section5-03.png`} alt="" />
                  <div className={`mask-layer`}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`indicator`}>
          <IndicatorSlider ref={this.indicatorRef} className={"scroll-indicator"} visibleWidth={this.state.visibleWidth}
            contentWidth={this.state.contentWidth} onScroll={current => {

              let scrollX = (this.scrollContainerRef.current.scrollWidth - this.scrollContainerRef.current.offsetWidth) * current
              // console.log("scrollx", scrollX, this.scrollContainerRef.current.scrollWidth)
              this.scrollContainerRef.current.scrollTo(scrollX, 0)
            }} />
          <div className={"text"}>滑动</div>
          <img className="indicator-right" src={`https://img.uigreat.com/pxfer/indicator-right.png`} alt=""/>
        </div>

      </div>
    )
  }

}

export default ScrollView